import React, { useContext, useMemo } from 'react'
import qs from 'qs'

import { Performance as PerformanceType, WillService } from 'types'
import { FilterContext } from 'store/filter'
import { ServiceTypeContext } from 'routes/context'
import { State as TrafficState } from 'store/filter/constants'
import { useAuth } from 'lib/hooks/useAuth'
import { useApi } from 'lib/hooks/useApi'
import { getISODateString } from 'lib/formatting/time'
import { GeneralError } from 'components/GeneralError'
import { ScreenReaderOnly } from 'components/ScreenReaderOnly'
import { ActiveFiltersList } from 'components/Filters/ActiveFiltersList'
import { useFilterQueryParamSync } from 'components/Filters/useFilterQueryParamSync'

import { PerformanceContext, Context } from './context'
import { Divider } from './Divider'
import { Summary } from './Summary'
import { Gifts } from './Gifts'
import { Demographics } from './Demographics'
import { ConversionFromRegistration } from './ConversionFromRegistration'

const buildQueryString = ({
  filters,
  willServiceType,
}: {
  filters: TrafficState['current']
  willServiceType: WillService
}): string => {
  const query: Record<string, string | string[]> = {
    traffic: filters.trafficTypeFilter,
  }
  if (filters.activeVoucherCodes.length)
    query.codes = filters.activeVoucherCodes
  if (filters.startDate) query.start = getISODateString(filters.startDate)
  if (filters.endDate) query.end = getISODateString(filters.endDate)
  if (filters.inheritancePrecedence)
    query.inheritancePrecedence = filters.inheritancePrecedence

  if (willServiceType !== 'all') {
    query.willServiceType = willServiceType
  }

  return qs.stringify(query, {
    arrayFormat: 'comma',
    encodeValuesOnly: false,
  })
}

export const Performance = (): React.ReactElement => {
  const [paramSyncIsInitialising] = useFilterQueryParamSync()
  const [{ current: filters }] = useContext(FilterContext)
  const willServiceType = useContext(ServiceTypeContext)
  const queryString = buildQueryString({ filters, willServiceType })

  const { metadata } = useAuth()
  const { charityName } = metadata
  const { loading, error, data } = useApi<PerformanceType>(
    paramSyncIsInitialising || !charityName
      ? null
      : `partner/${charityName}/performance${
          queryString ? `?${queryString}` : ''
        }`
  )

  const performanceProviderValue = useMemo(
    () => ({ loading, error, data }),
    [loading, error, data]
  )

  if (error) return <GeneralError />

  return (
    <PerformanceContext.Provider value={performanceProviderValue as Context}>
      {loading && (
        <ScreenReaderOnly aria-live="polite" role="status">
          Performance data is loading
        </ScreenReaderOnly>
      )}

      <ActiveFiltersList loading={loading} />
      <Divider margin={['S', 0]} />

      <Summary />
      <Divider />

      <Gifts />
      <Divider />

      {filters.trafficTypeFilter === 'campaign' && (
        <>
          <ConversionFromRegistration />
          <Divider />
        </>
      )}

      <Demographics />
      <Divider />
    </PerformanceContext.Provider>
  )
}
