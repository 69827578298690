import React from 'react'
import { UserConfig } from 'types'

import { State, ActionType } from '../constants'

export const reducer: React.Reducer<
  State,
  { type: ActionType; payload: UserConfig }
> = (state, action) => {
  switch (action.type) {
    case ActionType.SetPartner:
      return {
        ...state,
        partner: action.payload.activePartner,
        availablePartners: action.payload.partners,
      }

    default:
      return state
  }
}

export const setPartner =
  (
    dispatch: React.Dispatch<{
      type: ActionType.SetPartner
      payload: UserConfig
    }>
  ) =>
  (payload: UserConfig): void =>
    dispatch({
      type: ActionType.SetPartner,
      payload,
    })
