import { useContext, useEffect } from 'react'

import { useAuth } from 'lib/hooks/useAuth'
import { useApi } from 'lib/hooks/useApi'
import { UserConfig } from 'types'
import { GlobalContext } from 'store/global'

export const useFetchCharity = (): void => {
  const [, { setPartner }] = useContext(GlobalContext)
  const { isAuthenticated } = useAuth()

  const initialDataUrl = isAuthenticated ? 'partners/initial-data' : null

  const { data } = useApi<UserConfig>(initialDataUrl)

  useEffect(() => {
    if (!data) return

    setPartner(data)
  }, [setPartner, data])
}
