import React, { useContext } from 'react'
import styled from 'styled-components'
import { Grid } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { useAuth } from 'lib/hooks/useAuth'

import { ServiceTypeContext } from 'routes/context'
import { DefaultLayout } from 'layouts/DefaultLayout'
import { SideBar } from 'layouts/SideBar'
import { GeneralError } from 'components/GeneralError'

import { Performance } from 'components/Performance'
import { CustomerReports } from 'components/CustomerReports'

const StyledGrid = styled(Grid)`
  width: 100%;
  background-color: ${COLOR.WHITE};
`

export const Dashboard = (): React.ReactElement => {
  const { metadata, error } = useAuth()
  const { canDownloadPartnerDataSharingReports } = metadata
  const willServiceType = useContext(ServiceTypeContext)

  return (
    <DefaultLayout>
      <StyledGrid container containerPaddingTop={0} containerPaddingBottom={0}>
        {error ? (
          <Grid.Item
            padding={[0, 0, 'M']}
            paddingFromM={['L', 0]}
            paddingFromL={['XL', 0]}
          >
            <GeneralError />
          </Grid.Item>
        ) : (
          <>
            <SideBar />

            <Grid.Item
              spanFromM={8}
              spanFromXL={9}
              tag="main"
              padding={[0, 0, 'M']}
              paddingFromM={['L', 0]}
              paddingFromL={['XL', 0]}
            >
              <Performance />
              {canDownloadPartnerDataSharingReports === true &&
                willServiceType === 'all' && <CustomerReports />}
            </Grid.Item>
          </>
        )}
      </StyledGrid>
    </DefaultLayout>
  )
}
