import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Wrapper, Button, Image } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'

import { useAuth } from 'lib/hooks/useAuth'
import { Routes } from 'lib/constants'
import { GlobalContext } from 'store/global'

const StyledWrapper = styled(Wrapper)`
  box-shadow: inset 0px -2px 0px 0px ${COLOR.ACCENT.PRIMARY_120};
  position: relative;
  overflow: hidden;
`

const StyledBackgroundImage = styled(Image)`
  position: absolute;
  top: -300px;
  right: -500px;
  opacity: 0.9;
  filter: brightness(105%);
  user-select: none;

  ${screenMin.m`
  right: -250px;
  `}

  ${screenMin.xl`
  right: 0;
  `}
`

const StyledNav = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    z-index: 1;
  }
`

const StyledTabs = styled(Wrapper)`
  display: flex;

  ${screenMax.s`
    gap: ${GTR.S}
  `}
`

const StyledTab = styled(Link)<{ $active?: boolean }>`
  --tab-active-indicator-height: 4px;
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  margin-right: ${GTR.L};
  color: ${COLOR.BLACK};
  text-decoration: none;
  padding-bottom: ${GTR.S};
  position: relative;
  z-index: 1;

  ${({ $active }) =>
    $active &&
    `
    &::after {
      background-color: ${COLOR.BLACK};
      border-radius: var(--tab-active-indicator-height);
      bottom: calc(-1 * var(--tab-active-indicator-height));
      content: '';
      display: block;
      height: calc(2 * var(--tab-active-indicator-height));
      left: 0;
      position: absolute;
      width: 100%;
    }
  `}

  &:hover {
    text-decoration: none;
  }

  ${screenMax.s`
    margin: 0;
    text-align: center;
  `}
`

const StyledSpan = styled.span`
  margin-left: ${GTR.S};
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const StyledTitle = styled.h1`
  display: inline-block;
  flex: 1 0 0;
  margin: 0 ${GTR.S};
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const StyledSignOutButton = styled(Button.Plain)`
  color: ${COLOR.BLACK};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  --plain-button-flush-horizontal-boolean: 1;
`

export const Header = (): React.ReactElement => {
  const { logout } = useAuth()
  const [{ partner }] = useContext(GlobalContext)

  return (
    <StyledWrapper background={COLOR.ACCENT.PRIMARY_60}>
      <StyledBackgroundImage
        path="textures/detail-4-dark"
        width={1365}
        height={528}
      />
      <StyledNav
        tag="nav"
        container
        containerPaddingTop="S"
        containerPaddingBottom="S"
      >
        <Link to="/">
          <img
            src="/icons/logo-icon.svg"
            title="Farewill"
            alt="Farewill logo"
            height={38}
            width={26}
          />
        </Link>
        {partner && (
          <>
            <StyledSpan>|</StyledSpan>
            <StyledTitle>{partner.name}</StyledTitle>
          </>
        )}
        {logout && (
          <StyledSignOutButton
            type="button"
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.href } })
            }
          >
            Sign out
          </StyledSignOutButton>
        )}
      </StyledNav>

      {partner?.offers_telephone_wills && (
        <StyledTabs
          container
          containerPaddingTop="S"
          containerPaddingBottom={0}
        >
          <StyledTab
            $active={window.location.pathname === (Routes.Home as string)}
            to="/"
          >
            Overview
          </StyledTab>
          <StyledTab
            $active={window.location.pathname === (Routes.Online as string)}
            to="/online"
          >
            Online wills
          </StyledTab>
          <StyledTab
            $active={window.location.pathname === (Routes.Telephone as string)}
            to="/telephone"
          >
            Telephone wills
          </StyledTab>
          <StyledTab
            $active={window.location.pathname === (Routes.Resources as string)}
            to={Routes.Resources}
          >
            Campaign Support
          </StyledTab>
        </StyledTabs>
      )}
    </StyledWrapper>
  )
}
