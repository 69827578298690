import { UserConfig } from 'types'

export enum ActionType {
  SetPartner = 'SET_PARTNER',
}

export interface State {
  partner: UserConfig['activePartner'] | null
  availablePartners: UserConfig['partners'] | null
}

export const initialState: State = {
  partner: null,
  availablePartners: null,
}
