import { useAuth0, Auth0ContextInterface } from '@auth0/auth0-react'

export const APP_METADATA_KEY = 'https://dashboard.farewill.com/app_metadata'

type Auth0AppMetadata = {
  charityName?: string
  canDownloadPartnerDataSharingReports?: boolean
}

type Auth0Metadata = {
  [APP_METADATA_KEY]: Auth0AppMetadata
}

type AuthContext = Auth0ContextInterface<Auth0Metadata> & {
  metadata: Auth0AppMetadata
}

export type AuthState = {
  token: string
  user: AuthUser
}

export type AuthUser = {
  sub: string
  email: string
} & Auth0Metadata

export const useAuth = (): AuthContext => {
  const auth0 = useAuth0<Auth0Metadata>()

  if (window.Cypress) {
    /*
     * If we're running under Cypress the cy.login command will have
     * authenticated with the Auth0 API and put the auth state in
     * localStorage, so we deserialise and use that auth state.
     */
    const isAuthenticated = !!localStorage.getItem('auth0Cypress')
    const authState = JSON.parse(
      localStorage.getItem('auth0Cypress')
    ) as AuthState

    const user: AuthUser | Record<string, never> = isAuthenticated
      ? authState.user
      : {}

    return {
      ...auth0,
      isAuthenticated,
      metadata: { ...user[APP_METADATA_KEY] },
    }
  }

  const metadata = auth0.user ? { ...auth0.user[APP_METADATA_KEY] } : {}
  return { ...auth0, metadata }
}
