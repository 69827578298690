import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button, Divider } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { FilterContext } from 'store/filter'
import { dateIsInRange } from 'lib/helpers/date'
import { useAuth } from 'lib/hooks/useAuth'
import { MINIMUM_DATE } from './constants'

import { FiltersHeader } from './FiltersHeader'
import { DatesFilter } from './DatesFilter'
import { InheritancePrecedenceFilter } from './InheritancePrecedenceFilter'
import { TrafficTypeFilter } from './TrafficTypeFilter'

const StyledFiltersButton = styled(Button.BorderedDark)`
  border: 3px ${COLOR.GREY.MEDIUM} solid;
  padding: 10px 20px;
`

export const Filters = (): React.ReactElement => {
  const [{ next }, { applyFilters }] = useContext(FilterContext)
  const { metadata } = useAuth()
  const { charityName } = metadata

  const startDateIsValid =
    !next.startDate ||
    !next.endDate ||
    dateIsInRange({
      selectedDate: next.startDate,
      minDate: MINIMUM_DATE,
      maxDate: next.endDate,
    })

  const endDateIsValid =
    !next.startDate ||
    !next.endDate ||
    dateIsInRange({
      selectedDate: next.endDate,
      minDate: next.startDate,
      maxDate: new Date(),
    })

  return (
    <>
      <FiltersHeader />

      <DatesFilter
        startDateIsValid={startDateIsValid}
        endDateIsValid={endDateIsValid}
      />
      <Divider margin={['S', 0]} />

      <InheritancePrecedenceFilter />
      <Divider margin={['S', 0]} />

      <TrafficTypeFilter charityName={charityName} />

      <StyledFiltersButton
        stretch
        onClick={applyFilters}
        disabled={!startDateIsValid || !endDateIsValid}
      >
        Apply filters
      </StyledFiltersButton>
    </>
  )
}
