import * as Sentry from '@sentry/browser'
import axios, { AxiosError } from 'axios'
import { client } from 'lib/axios/apiClient'

import { ApiErrorResponseBody } from 'types'
import {
  ActionType,
  type Action,
  type TriggerGenerateAction,
  type TriggerRegenerateAction,
} from './reducer'

import type {
  EmailGenerationJobQueuedResponse,
  EmailGenerationResponse,
  EmailGenerationResult,
  Tab,
  TransformedEmailGenerationResponse,
} from './types'

const responseTransformer = (
  response: EmailGenerationResponse | ApiErrorResponseBody
  // eslint-disable-next-line consistent-return
): TransformedEmailGenerationResponse | ApiErrorResponseBody => {
  // Don't transform error responses…
  if ('errors' in response) {
    return response
  }

  return {
    emails: Array.isArray(response.emails)
      ? response.emails.reduce(
          (acc, email) => {
            acc[email.template] = {
              ...email,
              threadId: response.threadId,
            }
            return acc
          },
          {} as Record<Tab['key'], EmailGenerationResult>
        )
      : response.emails,
    status: response.status,
    threadId: response.threadId,
  }
}

export const asyncActionHandlers = {
  GENERATE:
    ({ dispatch }: { dispatch: React.Dispatch<Action> }) =>
    async (action: TriggerGenerateAction) => {
      try {
        const INITIAL_WAIT_BEFORE_POLL = window.Cypress ? 50 : 5000
        const POLL_INTERVAL = window.Cypress ? 50 : 1500
        const { audience, partnerId } = action.payload

        dispatch({
          type: ActionType.GenerationStart,
          payload: {
            audience,
            generationCount: 0,
          },
        })

        /*
         * Trigger the generation. Backend returns the job ID for the queued
         * background job.
         */
        const {
          data: { jobId },
        } = await client.request<EmailGenerationJobQueuedResponse>({
          url: `/partner/${partnerId}/get-emails?audience=${audience}`,
        })

        const timeout = (ms: number) => {
          return new Promise<void>((resolve) => {
            setTimeout(resolve, ms)
          })
        }

        const poll = (
          job: string
        ): Promise<TransformedEmailGenerationResponse> => {
          return client
            .request<TransformedEmailGenerationResponse>({
              url: `/partner/${partnerId}/get-emails/${job}`,
              transformResponse: [].concat(
                axios.defaults.transformResponse,
                responseTransformer
              ),
            })
            .then(({ data }) => {
              if (data.status === 'completed') {
                return data
              }

              if (data.status === 'failed') {
                return Promise.reject(new Error('Generation failed'))
              }

              // If the status is not 'completed', wait for a short while then poll again
              return timeout(POLL_INTERVAL).then(() => poll(job))
            })
        }

        /*
         * Wait a few seconds before polling to avoid hitting rate limit. We know
         * the request is going to take a least 5 seconds anyway.
         */
        await timeout(INITIAL_WAIT_BEFORE_POLL)
        const result = await poll(jobId)

        if (!result) {
          throw new Error('Error generating emails - Missing `result`')
        }

        dispatch({
          type: ActionType.GenerationEnd,
          payload: {
            audience,
            emails: result.emails,
            threadId: result.threadId,
          },
        })
      } catch (error) {
        let err: string
        if (error instanceof AxiosError) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (error.response?.data?.errors) {
            const e = error.response?.data as ApiErrorResponseBody
            err = e.errors[0]?.detail || e.errors[0]?.code
          }
        } else if (error instanceof Error) {
          err = error.message
        }

        err = err || 'Unknown error'

        dispatch({
          type: ActionType.GenerationError,
          payload: {
            audience: action.payload.audience,
            error: err,
          },
        })
        console.error('Error generating emails', error)

        Sentry.withScope((scope) => {
          scope.setExtra('error', error)
          Sentry.captureException(new Error('Error generating emails'))
        })
      }
    },

  REGENERATE:
    ({ dispatch }: { dispatch: React.Dispatch<Action> }) =>
    async (action: TriggerRegenerateAction) => {
      try {
        const { audience, email, instructions, partnerId, threadId } =
          action.payload

        dispatch({
          type: ActionType.RegenerationStart,
          payload: {
            audience,
            email,
            instructions,
          },
        })

        const { data } =
          await client.request<TransformedEmailGenerationResponse>({
            url: `/partner/${partnerId}/update-emails`,
            data: {
              prompt: instructions,
              template: email,
              threadId,
            },
            method: 'POST',
            transformResponse: [].concat(
              axios.defaults.transformResponse,
              responseTransformer
            ),
          })

        const { body, subject } = data.emails[email]

        dispatch({
          type: ActionType.RegenerationEnd,
          payload: {
            audience,
            email,
            content: body,
            subject,
            threadId,
          },
        })
      } catch (error) {
        let err: string
        if (error instanceof AxiosError) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (error.response?.data?.errors) {
            const e = error.response?.data as ApiErrorResponseBody
            err = e.errors[0]?.detail || e.errors[0]?.code
          }
        }
        err = err || 'Unknown error'

        dispatch({
          type: ActionType.GenerationError,
          payload: {
            audience: action.payload.audience,
            error: err,
          },
        })
        console.error('Error generating emails', error)

        Sentry.withScope((scope) => {
          scope.setExtra('error', error)
          Sentry.captureException(new Error('Error generating emails'))
        })
      }
    },
}
